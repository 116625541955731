export const EMPREGADO = {
  extraFields: { codigoEsocial: '101', codigoSefip: '01' },
  label: '01 / 101 - Empregado',
  value: 'EMPREGADO',
};
export const TRABAVULSO = {
  extraFields: { codigoEsocial: '202', codigoSefip: '02' },
  label: '02 / 202 - Trabalhador Avulso',
  value: 'TRABAVULSO',
};
export const TRAB_CONTR_NAO_VINC_RGPS = {
  extraFields: { codigoEsocial: '101', codigoSefip: '03' },
  label: '03 / 101 - Trabalhador não vinculado ao RGPS, mas com direito ao FGTS',
  value: 'TRAB_CONTR_NAO_VINC_RGPS',
};
export const EMP_CONTR_PRAZO_DETERMINADO = {
  extraFields: { codigoEsocial: '105', codigoSefip: '04' },
  label: '04 / 105 - Empregado - Prazo determinado (Lei n° 9.601/98)',
  value: 'EMP_CONTR_PRAZO_DETERMINADO',
};
export const DIRETOR_NAO_EMP_COM_FGTS = {
  extraFields: { codigoEsocial: '721', codigoSefip: '05' },
  label: '05 / 721 - C.I. - Diretor não empregado com FGTS (Lei nº 8.036/90, art. 16)',
  value: 'DIRETOR_NAO_EMP_COM_FGTS',
};
export const EMPREGADO_DOMESTICO = {
  extraFields: { codigoEsocial: '104', codigoSefip: '06' },
  label: '06 / 104 - Empregado doméstico',
  value: 'EMPREGADO_DOMESTICO',
};
export const MENOR_APRENDIZ = {
  extraFields: { codigoEsocial: '103', codigoSefip: '07' },
  label: '07 / 103 - Menor Aprendiz (Lei nº 11.180/2005)',
  value: 'MENOR_APRENDIZ',
};
export const DIRETOR_EMPRESARIO_NAO_EMP_SEM_FGTS = {
  extraFields: { codigoEsocial: '722', codigoSefip: '11' },
  label: '11 / 722 - C.I. - Diretor não empregado e demais empresários sem FGTS',
  value: 'DIRETOR_EMPRESARIO_NAO_EMP_SEM_FGTS',
};
export const DEMAIS_AGENTES_PUBLICOS = {
  extraFields: { codigoEsocial: '309', codigoSefip: '12' },
  label: '12 / 309 - Demais agentes públicos',
  value: 'DEMAIS_AGENTES_PUBLICOS',
};
export const AUTONOMO_CONTRIB_REMUNERACAO = {
  extraFields: { codigoEsocial: '701', codigoSefip: '13' },
  label: '13 / 701 - C.I. – Trabalhador autônomo com contribuição sobre remuneração; trabalhador associado à cooperativa de produção',
  value: 'AUTONOMO_CONTRIB_REMUNERACAO',
};
export const AUTONOMO_CONTRIB_SAL_BASE = {
  extraFields: { codigoEsocial: '701', codigoSefip: '14' },
  label: '14 / 701 - C.I. - Trabalhador autônomo com contribuição sobre salário base',
  value: 'AUTONOMO_CONTRIB_SAL_BASE',
};
export const TRANSPORTADOR_CONTRIB_REMUNERACAO = {
  extraFields: { codigoEsocial: '712', codigoSefip: '15' },
  label: '15 / 712 - C.I. - Transportador autônomo, com contribuição sobre remuneração',
  value: 'TRANSPORTADOR_CONTRIB_REMUNERACAO',
};
export const TRANSPORTADOR_CONTRIB_SAL_BASE = {
  extraFields: { codigoEsocial: '712', codigoSefip: '16' },
  label: '16 / 712 - C.I. - Transportador autônomo, com contribuição sobre salário base',
  value: 'TRANSPORTADOR_CONTRIB_SAL_BASE',
};
export const COOPERADO = {
  extraFields: { codigoEsocial: '731', codigoSefip: '17' },
  label: '17 / 731 - C.I. - Cooperado que presta serviços a empresas contratantes da cooperativa de trabalho',
  value: 'COOPERADO',
};
export const TRANSPORTADOR_COOPERADO = {
  extraFields: { codigoEsocial: '734', codigoSefip: '18' },
  label: '18 / 734 - C.I. - Transportador cooperado que presta serviços a empresas contratantes da cooperativa de trabalho',
  value: 'TRANSPORTADOR_COOPERADO',
};
export const AGENTE_POLITICO = {
  extraFields: { codigoEsocial: '303', codigoSefip: '19' },
  label: '19 / 303 - Agente Político',
  value: 'AGENTE_POLITICO',
};
export const SERV_PUBLICO_TEMPORARIO = {
  extraFields: { codigoEsocial: '306', codigoSefip: '20' },
  label: '20 / 306 - Servidor Público ocupante, exclusivamente, de cargo em comissão, Servidor Público ocupante de cargo temporário',
  value: 'SERV_PUBLICO_TEMPORARIO',
};
export const SERV_PUBLICO_EFETIVO = {
  extraFields: { codigoEsocial: '301', codigoSefip: '21' },
  label: '21 / 301 - Servidor Público titular de cargo efetivo, magistrado, membro do Ministério Público e do Tribunal e Conselho de Contas',
  value: 'SERV_PUBLICO_EFETIVO',
};
export const CI_CONTRATADO_POR_CI = {
  extraFields: { codigoEsocial: '101', codigoSefip: '22' },
  label:
    '22 / 101 - C.I. - Contratado por outro C.I. equiparado a empresa ou por produtor rural pessoa física ou por missão diplomática e repartição consular de carreira estrangeiras',
  value: 'CI_CONTRATADO_POR_CI',
};
export const TRANSP_AUT_CONTRATADO_POR_CI = {
  extraFields: { codigoEsocial: '101', codigoSefip: '23' },
  label:
    '23 / 101 - C.I. - Transportador autônomo contratado por outro C.I. equiparado à empresa ou por produtor rural pessoa física ou por missão diplomática e ' +
    'repartição consular de carreira estrangeiras',
  value: 'TRANSP_AUT_CONTRATADO_POR_CI',
};
export const COOPERADO_PRESTA_SERV_ENTIDADE_BENE = {
  extraFields: { codigoEsocial: '101', codigoSefip: '24' },
  label:
    '24 / 101 - C.I. - Cooperado que presta serviços à entidade beneficente de assistência social isenta da cota patronal ou a pessoa física,' +
    ' por intermédioda cooperativa de trabalho',
  value: 'COOPERADO_PRESTA_SERV_ENTIDADE_BENE',
};
export const TRANSP_COOP_PRESTA_SERV_ENTIDADE_BENE = {
  extraFields: { codigoEsocial: '101', codigoSefip: '25' },
  label:
    '25 / 101 - C.I. - Transportador cooperado que presta serviços a entidade beneficente de assistência social isenta da cota patronal ou a pessoa física, ' +
    'por intermédio da cooperativa de trabalho',
  value: 'TRANSP_COOP_PRESTA_SERV_ENTIDADE_BENE',
};
export const DIRIGENTE_SINDICAL = {
  extraFields: { codigoEsocial: '401', codigoSefip: '26' },
  label:
    '26 / 401 - Dirigente sindical, em relação ao adicional pago pelo sindicato; magistrado classista temporário da Justiça do Trabalho; magistrado dos Tribunais Eleitorais, ' +
    'quando, nas três situações, for mantida a qualidade de segurado empregado (sem FGTS)',
  value: 'DIRIGENTE_SINDICAL',
};
export const EMPREGADO_TRABALHADOR_RURAL = {
  extraFields: { codigoEsocial: '102' },
  label: '102 - Empregado - Trabalhador Rural por Pequeno Prazo da Lei 11.718/2008',
  value: 'EMPREGADO_TRABALHADOR_RURAL',
};
export const TRABALHADOR_TEMPORARIO = {
  extraFields: { codigoEsocial: '106' },
  label: '106 - Trabalhador Temporário - contrato nos termos da Lei 6.019/74',
  value: 'TRABALHADOR_TEMPORARIO',
};
export const EMPREGADO_TRABALHADOR_INTERMITENTE = {
  extraFields: { codigoEsocial: '111' },
  label: '111 - Empregado - contrato de trabalho intermitente',
  value: 'EMPREGADO_TRABALHADOR_INTERMITENTE',
};
export const TRABALHADOR_AVULSO_PORTUARIO = {
  extraFields: { codigoEsocial: '201' },
  label: '201 - Trabalhador Avulso Portuário',
  value: 'TRABALHADOR_AVULSO_PORTUARIO',
};
export const SERVIDOR_PUBLICO_EXCLUSIVO = {
  extraFields: { codigoEsocial: '302' },
  label: '302 - Servidor Público Ocupante de Cargo exclusivo em comissão',
  value: 'SERVIDOR_PUBLICO_EXCLUSIVO',
};
export const SERVIDOR_PUBLICO_CONSELHO = {
  extraFields: { codigoEsocial: '305' },
  label:
    '305 - Servidor Público indicado para conselho ou órgão deliberativo, na condição de representante do governo, órgão ou entidade da administração pública',
  value: 'SERVIDOR_PUBLICO_CONSELHO',
};
export const MILITAR_EFETIVO = {
  extraFields: { codigoEsocial: '307' },
  label: '307 - Militar efetivo',
  value: 'MILITAR_EFETIVO',
};
export const CONSCRITO = {
  extraFields: { codigoEsocial: '308' },
  label: '308 - Conscrito',
  value: 'CONSCRITO',
};
export const TRABALHADOR_CEDIDO = {
  extraFields: { codigoEsocial: '410' },
  label: '410 - Trabalhador cedido - informação prestada pelo Cessionário',
  value: 'TRABALHADOR_CEDIDO',
};
export const CI_TRANSP_AUT_PASSAGEIROS = {
  extraFields: { codigoEsocial: '711' },
  label: '711 - Contribuinte individual - Transportador autônomo de passageiros',
  value: 'CI_TRANSP_AUT_PASSAGEIROS',
};
export const CI_TRANSP_AUT_CARGA = {
  extraFields: { codigoEsocial: '712' },
  label: '712 - Contribuinte individual - Transportador autônomo de carga',
  value: 'CI_TRANSP_AUT_CARGA',
};
export const CI_EMPRESARIO_SOCIO = {
  extraFields: { codigoEsocial: '723' },
  label: '723 - Contribuinte individual - empresários, sócios e membro de conselho de administração ou fiscal',
  value: 'CI_EMPRESARIO_SOCIO',
};
export const CI_COOPERADO_COOPERATIVA_PRODUCAO = {
  extraFields: { codigoEsocial: '738' },
  label: '738 - Contribuinte individual - Cooperado filiado a Cooperativa de Produção',
  value: 'CI_COOPERADO_COOPERATIVA_PRODUCAO',
};
export const CI_MICROEMPREENDEDOR_INDIVIDUAL = {
  extraFields: { codigoEsocial: '741' },
  label: '741 - Contribuinte individual - Microempreendedor Individual',
  value: 'CI_MICROEMPREENDEDOR_INDIVIDUAL',
};
export const CI_MAGISTRADO_CLASSISTA = {
  extraFields: { codigoEsocial: '751' },
  label:
    '751 - Contribuinte individual - magistrado classista temporário da Justiça do Trabalho ou da Justiça Eleitoral que seja aposentado de qualquer regime previdenciário',
  value: 'CI_MAGISTRADO_CLASSISTA',
};
export const CI_ASSOCIADO_ELEITO_COOPERATIVA = {
  extraFields: { codigoEsocial: '761' },
  label:
    '761 - Contribuinte individual - Associado eleito para direção de Cooperativa, associação ou entidade de classe qualquer natureza ou finalidade, bem como o síndico ' +
    'ou administrador eleito para exercer atividade de direção condominial, desde que recebam remuneração',
  value: 'CI_ASSOCIADO_ELEITO_COOPERATIVA',
};
export const CI_MEMBRO_CONSELHO_TUTELAR = {
  extraFields: { codigoEsocial: '771' },
  label: '771 - Contribuinte individual - Membro de conselho tutelar, nos termos da Lei nº 8.069, de 13 julho 1990',
  value: 'CI_MEMBRO_CONSELHO_TUTELAR',
};
export const MINISTRO_RELIGIOSO = {
  extraFields: { codigoEsocial: '781' },
  label: '781 - Ministro de confissão religiosa ou membro de vida consagrada, de congregação ou de ordem religiosa',
  value: 'MINISTRO_RELIGIOSO',
};
export const ESTAGIARIO = {
  extraFields: { codigoEsocial: '901', codigoSefip: '45' },
  label: '45 / 901 - Estagiário',
  value: 'ESTAGIARIO',
};
export const MEDICO_RESIDENTE = {
  extraFields: { codigoEsocial: '902' },
  label: '902 - Médico Residente',
  value: 'MEDICO_RESIDENTE',
};
export const BOLSISTA = {
  extraFields: { codigoEsocial: '903' },
  label: '903 - Bolsista, nos termos da lei 8958/1994',
  value: 'BOLSISTA',
};
export const PARTICIPANTE_CURSO_SEM_VINCULO = {
  extraFields: { codigoEsocial: '904' },
  label: '904 - Participante de curso de formação, como etapa de concurso público, sem vínculo de emprego/estatutário',
  value: 'PARTICIPANTE_CURSO_SEM_VINCULO',
};
export const ATLETA_NAO_PROFISSIONAL = {
  extraFields: { codigoEsocial: '905' },
  label: '905 - Atleta não profissional em formação que receba bolsa',
  value: 'ATLETA_NAO_PROFISSIONAL',
};
export const EMPREGADO_CONTRATO_VERDE_AMARELO_SEM_ANT_FGTS = {
  extraFields: { codigoEsocial: '107' },
  label: '107 - Empregado - Contrato de trabalho Verde e Amarelo - sem acordo para antecipação mensal da multa rescisória do FGTS',
  value: 'EMPREGADO_CONTRATO_VERDE_AMARELO_SEM_ANT_FGTS',
};
export const EMPREGADO_CONTRATO_VERDE_AMARELO_COM_ANT_FGTS = {
  extraFields: { codigoEsocial: '108' },
  label: '108 - Empregado - Contrato de trabalho Verde e Amarelo - com acordo para antecipação mensal da multa rescisória do FGTS',
  value: 'EMPREGADO_CONTRATO_VERDE_AMARELO_COM_ANT_FGTS',
};

export const DIRIGENTE_SINDICAL_SEGURADO_ESPECIAL = {
  extraFields: { codigoEsocial: '501' },
  label: '501 - Dirigente sindical - Segurado especial',
  value: 'DIRIGENTE_SINDICAL_SEGURADO_ESPECIAL',
};

export const CATEGORIA_ENUM_VALUES = [
  EMPREGADO,
  TRABAVULSO,
  TRAB_CONTR_NAO_VINC_RGPS,
  EMP_CONTR_PRAZO_DETERMINADO,
  DIRETOR_NAO_EMP_COM_FGTS,
  EMPREGADO_DOMESTICO,
  MENOR_APRENDIZ,
  DIRETOR_EMPRESARIO_NAO_EMP_SEM_FGTS,
  DEMAIS_AGENTES_PUBLICOS,
  AUTONOMO_CONTRIB_REMUNERACAO,
  AUTONOMO_CONTRIB_SAL_BASE,
  TRANSPORTADOR_CONTRIB_REMUNERACAO,
  TRANSPORTADOR_CONTRIB_SAL_BASE,
  COOPERADO,
  TRANSPORTADOR_COOPERADO,
  AGENTE_POLITICO,
  SERV_PUBLICO_TEMPORARIO,
  SERV_PUBLICO_EFETIVO,
  CI_CONTRATADO_POR_CI,
  TRANSP_AUT_CONTRATADO_POR_CI,
  COOPERADO_PRESTA_SERV_ENTIDADE_BENE,
  TRANSP_COOP_PRESTA_SERV_ENTIDADE_BENE,
  DIRIGENTE_SINDICAL,
  EMPREGADO_TRABALHADOR_RURAL,
  TRABALHADOR_TEMPORARIO,
  EMPREGADO_TRABALHADOR_INTERMITENTE,
  TRABALHADOR_AVULSO_PORTUARIO,
  SERVIDOR_PUBLICO_EXCLUSIVO,
  SERVIDOR_PUBLICO_CONSELHO,
  MILITAR_EFETIVO,
  CONSCRITO,
  TRABALHADOR_CEDIDO,
  CI_TRANSP_AUT_PASSAGEIROS,
  CI_TRANSP_AUT_CARGA,
  CI_EMPRESARIO_SOCIO,
  CI_COOPERADO_COOPERATIVA_PRODUCAO,
  CI_MICROEMPREENDEDOR_INDIVIDUAL,
  CI_MAGISTRADO_CLASSISTA,
  CI_ASSOCIADO_ELEITO_COOPERATIVA,
  CI_MEMBRO_CONSELHO_TUTELAR,
  MINISTRO_RELIGIOSO,
  ESTAGIARIO,
  MEDICO_RESIDENTE,
  BOLSISTA,
  PARTICIPANTE_CURSO_SEM_VINCULO,
  ATLETA_NAO_PROFISSIONAL,
  EMPREGADO_CONTRATO_VERDE_AMARELO_SEM_ANT_FGTS,
  EMPREGADO_CONTRATO_VERDE_AMARELO_COM_ANT_FGTS,
  DIRIGENTE_SINDICAL_SEGURADO_ESPECIAL,
];

export const SOCIOS_VALUES = [DIRETOR_NAO_EMP_COM_FGTS, DIRETOR_EMPRESARIO_NAO_EMP_SEM_FGTS, CI_EMPRESARIO_SOCIO];

export const AUTONOMOS_VALUES = [
  AUTONOMO_CONTRIB_REMUNERACAO,
  TRANSP_COOP_PRESTA_SERV_ENTIDADE_BENE,
  AUTONOMO_CONTRIB_SAL_BASE,
  TRANSPORTADOR_CONTRIB_REMUNERACAO,
  TRANSPORTADOR_COOPERADO,
  COOPERADO,
  TRANSPORTADOR_CONTRIB_SAL_BASE,
  TRANSP_AUT_CONTRATADO_POR_CI,
  CI_TRANSP_AUT_PASSAGEIROS,
  CI_TRANSP_AUT_CARGA,
];

export const TRANSPORTADOR_VALUES = [
  TRANSPORTADOR_CONTRIB_REMUNERACAO,
  TRANSPORTADOR_CONTRIB_SAL_BASE,
  TRANSPORTADOR_COOPERADO,
  TRANSP_AUT_CONTRATADO_POR_CI,
  TRANSP_COOP_PRESTA_SERV_ENTIDADE_BENE,
];

export const SEM_VINCULO_ESOCIAL_VALUES = [
  TRABALHADOR_AVULSO_PORTUARIO,
  TRABAVULSO,
  SERVIDOR_PUBLICO_CONSELHO,
  CONSCRITO,
  DIRIGENTE_SINDICAL,
  TRABALHADOR_CEDIDO,
  AUTONOMO_CONTRIB_REMUNERACAO,
  AUTONOMO_CONTRIB_SAL_BASE,
  CI_TRANSP_AUT_PASSAGEIROS,
  CI_TRANSP_AUT_CARGA,
  DIRETOR_NAO_EMP_COM_FGTS,
  DIRETOR_EMPRESARIO_NAO_EMP_SEM_FGTS,
  CI_EMPRESARIO_SOCIO,
  COOPERADO,
  TRANSPORTADOR_COOPERADO,
  CI_COOPERADO_COOPERATIVA_PRODUCAO,
  CI_MICROEMPREENDEDOR_INDIVIDUAL,
  CI_MAGISTRADO_CLASSISTA,
  CI_ASSOCIADO_ELEITO_COOPERATIVA,
  CI_MEMBRO_CONSELHO_TUTELAR,
  MINISTRO_RELIGIOSO,
  ESTAGIARIO,
  MEDICO_RESIDENTE,
  BOLSISTA,
  PARTICIPANTE_CURSO_SEM_VINCULO,
  ATLETA_NAO_PROFISSIONAL,
  TRANSPORTADOR_CONTRIB_REMUNERACAO,
];

export const CATEGORIAS_741_SEFIP_ENUM_VALUES = [AUTONOMO_CONTRIB_REMUNERACAO, TRANSPORTADOR_CONTRIB_REMUNERACAO];

export const DOMESTICO_TEMPORARIO_VALUES = [EMPREGADO_DOMESTICO, TRABALHADOR_TEMPORARIO];

export const CATEGORIAS_SEFIP_VALUES = [
  EMPREGADO,
  TRABAVULSO,
  TRAB_CONTR_NAO_VINC_RGPS,
  EMP_CONTR_PRAZO_DETERMINADO,
  DIRETOR_NAO_EMP_COM_FGTS,
  EMPREGADO_DOMESTICO,
  MENOR_APRENDIZ,
  DIRETOR_EMPRESARIO_NAO_EMP_SEM_FGTS,
  DEMAIS_AGENTES_PUBLICOS,
  AUTONOMO_CONTRIB_REMUNERACAO,
  AUTONOMO_CONTRIB_SAL_BASE,
  TRANSPORTADOR_CONTRIB_REMUNERACAO,
  TRANSPORTADOR_CONTRIB_SAL_BASE,
  COOPERADO,
  TRANSPORTADOR_COOPERADO,
  AGENTE_POLITICO,
  SERV_PUBLICO_TEMPORARIO,
  SERV_PUBLICO_EFETIVO,
  CI_CONTRATADO_POR_CI,
  TRANSP_AUT_CONTRATADO_POR_CI,
  COOPERADO_PRESTA_SERV_ENTIDADE_BENE,
  TRANSP_COOP_PRESTA_SERV_ENTIDADE_BENE,
  DIRIGENTE_SINDICAL,
  EMPREGADO_TRABALHADOR_INTERMITENTE,
  CI_EMPRESARIO_SOCIO,
];

export const CATEGORIAS_SEM_OBRIGATORIEDADE_JORNADA_TRABALHO = [
  AUTONOMO_CONTRIB_REMUNERACAO,
  AUTONOMO_CONTRIB_SAL_BASE,
  CI_TRANSP_AUT_PASSAGEIROS,
  TRANSPORTADOR_CONTRIB_REMUNERACAO,
  TRANSPORTADOR_CONTRIB_SAL_BASE,
  CI_TRANSP_AUT_CARGA,
  DIRETOR_NAO_EMP_COM_FGTS,
  DIRETOR_EMPRESARIO_NAO_EMP_SEM_FGTS,
  CI_EMPRESARIO_SOCIO,
  COOPERADO,
  TRANSPORTADOR_COOPERADO,
  CI_COOPERADO_COOPERATIVA_PRODUCAO,
  CI_MICROEMPREENDEDOR_INDIVIDUAL,
  CI_MAGISTRADO_CLASSISTA,
  CI_ASSOCIADO_ELEITO_COOPERATIVA,
  CI_MEMBRO_CONSELHO_TUTELAR,
  MINISTRO_RELIGIOSO,
];

export const CATEGORIAS_PARA_101 = [
  EMPREGADO_TRABALHADOR_RURAL,
  MENOR_APRENDIZ,
  EMP_CONTR_PRAZO_DETERMINADO,
  TRABALHADOR_TEMPORARIO,
  EMPREGADO_CONTRATO_VERDE_AMARELO_SEM_ANT_FGTS,
  EMPREGADO_CONTRATO_VERDE_AMARELO_COM_ANT_FGTS,
  EMPREGADO_TRABALHADOR_INTERMITENTE,
];

export const CATEGORIAS_PERMITIDO_ALTERACAO = [
  EMPREGADO,
  MENOR_APRENDIZ,
  EMPREGADO_TRABALHADOR_RURAL,
  EMP_CONTR_PRAZO_DETERMINADO,
  TRABALHADOR_TEMPORARIO,
  EMPREGADO_CONTRATO_VERDE_AMARELO_SEM_ANT_FGTS,
  EMPREGADO_CONTRATO_VERDE_AMARELO_COM_ANT_FGTS,
  EMPREGADO_TRABALHADOR_INTERMITENTE,
];

export const CATEGORIAS_PARA_111 = [EMPREGADO, MENOR_APRENDIZ];

export const categoriaEnumFromValue = (value: string) => {
  const categoriaEnum = CATEGORIA_ENUM_VALUES.find(it => it.value === value);
  return categoriaEnum ? categoriaEnum : null;
};
