import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import {
  CALIMA_DANGER,
  CALIMA_ORANGE,
  CALIMA_SUCCESS,
  CALIMA_WARNING,
  ColumnsProps,
  DefaultSortProps,
  enumColumn,
  ERROR,
  OrderType,
  SearchDataGrid,
  showToast,
} from 'summer';
import { AGUARDANDO, AVISO, ERRO, FINALIZADO, JOB_STATUS_ENUM_VALUES } from '../../../models/enumerated/ger/jobStatusEnum';
import { cancelJob, findAllJobs, removeFinishedJobs } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { fecharJanelaProcessos } from '../../../store/ducks/global.duck';

import './JobInfoView.css';

const defaultSorted: DefaultSortProps[] = [
  {
    dataField: 'mensagem',
    order: OrderType.ASC,
  },
];

const LABEL_COLOR = {
  [FINALIZADO.value]: CALIMA_SUCCESS,
  [ERRO.value]: CALIMA_DANGER,
  [AGUARDANDO.value]: CALIMA_WARNING,
  [AVISO.value]: CALIMA_ORANGE,
};

const JobInfoView: FC = () => {
  const [listaJobs, setListaJobs] = useState([]);

  const {
    jobReducer: { data },
    globalReducer: { exibirJanelaProcessos },
  } = useSelector<Reducers, Reducers>(state => state);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllJobs();
  }, [data]);

  const getAllJobs = () => {
    findAllJobs({
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: result => setListaJobs(result),
    });
  };

  const removeJobs = () => {
    removeFinishedJobs({
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: () => getAllJobs(),
    });
  };

  const toggle = () => dispatch(fecharJanelaProcessos());

  const cancelar = id => {
    cancelJob(id, {
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: () => null,
    });
  };

  const columns: ColumnsProps[] = [
    {
      dataField: 'mensagem',
      sort: true,
      text: 'Processo',
    },
    enumColumn(
      {
        dataField: 'status',
        style: (_, row) => ({ color: (row && LABEL_COLOR[row.status]) || '#33aff2' }),
        text: 'Situação',
      },
      JOB_STATUS_ENUM_VALUES
    ),
    {
      dataField: 'dtInicio',
      sort: true,
      text: 'Início do processo',
    },
    {
      dataField: 'dtInicioExecucao',
      sort: true,
      text: 'Data início da execução',
    },
    {
      dataField: 'dtFim',
      sort: true,
      text: 'Data fim do processo',
    },
    {
      dataField: 'duracao',
      sort: true,
      text: 'Duração do processo',
    },
    {
      dataField: 'duracaoExecucao',
      sort: true,
      text: 'Duração da execução processo',
    },
    {
      dataField: 'acoes',
      formatter: (_, row) => {
        const id = `cancelar-exec-${row.id}`;
        return (
          row.status === AGUARDANDO.value && (
            <>
              <button className="cancelar-exec" id={id} onClick={cancelar.bind(null, row.id)}>
                Cancelar execução
                <FontAwesomeIcon icon="times" />
              </button>
              <UncontrolledTooltip target={id}>Remover este processo da fila de execução.</UncontrolledTooltip>
            </>
          )
        );
      },
      text: 'Ações',
    },
  ];

  return (
    <Modal isOpen={exibirJanelaProcessos} toggle={toggle} size="xl" centered={true}>
      <ModalHeader className="jobinfo-header py-2" toggle={toggle}>
        Utilitários > Processo
      </ModalHeader>
      <ModalBody>
        <SearchDataGrid
          columns={columns}
          defaultSorted={defaultSorted}
          data={listaJobs}
          disableDoubleClick={true}
          hideActionBar={true}
          extraData={{ hideActionColumn: true }}
        />
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={removeJobs} color="danger" className="mb-2">
          <span>Remover finalizados</span>
          <FontAwesomeIcon icon="times" />
        </Button>
        <Button size="sm" onClick={getAllJobs} color="first" className="mb-2 mr-2">
          <span>Atualizar</span>
          <FontAwesomeIcon icon="sync" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default JobInfoView;
