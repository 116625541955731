import { createActions, handleActions } from 'redux-actions';
import { UsuarioHistAcesso } from '../../models/dtos/ger/usuarioHistAcesso';

export interface HistAcessoState {
  favs?: UsuarioHistAcesso[];
  recents?: UsuarioHistAcesso[];
}

const initialState: HistAcessoState = {
  favs: [],
  recents: [],
};

export const actions = createActions({
  ADD_RECENTS: item => item,
  SET_FAVS: list => list,
  SET_RECENTS: list => list,
});

export const reducer = handleActions<HistAcessoState>(
  {
    [actions.addRecents.toString()]: (state: HistAcessoState, action: any) => ({
      ...state,
      recents: [action.payload, ...state.recents.filter(it => it.modulo !== action.modulo || it.itemMenu !== action.itemMenu)],
    }),
    [actions.setFavs.toString()]: (state: HistAcessoState, action: any) => ({
      ...state,
      favs: [...action.payload],
    }),
    [actions.setRecents.toString()]: (state: HistAcessoState, action: any) => ({
      ...state,
      recents: [...action.payload],
    }),
  },
  initialState
);
