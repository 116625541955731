import React, { FC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'summer';
import { Reducers } from '../../../../store/ducks';
import { toggleModalPgDasIntegraContador } from '../../../../store/ducks/global.duck';
import FullSizeModal, { FullSizeModalRef } from '../../../components/fullSizeModal/FullSizeModal';

import adIntegraContadorPgDas from '../../../../assets/adIntegraContadorPgDas.png';
import './ModalPGDasIntegraContador.css';

const ModalPGDasIntegraContador: FC = () => {
  const {
    globalReducer: { showModalPgDasIntegraContador },
  } = useSelector<Reducers, Reducers>(state => state);

  const modal = useRef<FullSizeModalRef>();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(toggleModalPgDasIntegraContador());
    window.sessionStorage.setItem('MENSAGEM_PGDAS_INTEGRA', 'true');
  };

  return (
    showModalPgDasIntegraContador && (
      <FullSizeModal className="modal-pgdas-integra-contador" onClose={onClose} ref={modal} aspectRatio={1.5399}>
        <div className="slide">
          <img src={adIntegraContadorPgDas} alt="" />
        </div>
        <div className="ad-footer">
          <div className="btns">
            <a className={'btn-conhecer'} href="https://www.calimaerp.com/integracontador" target="_blank" rel="noopener noreferrer">
              <button>Conhecer o Integra Contador</button>
            </a>
            <button onClick={onClose} className={'btn-prosseguir'}>
              Prosseguir manualmente
            </button>
          </div>
        </div>
      </FullSizeModal>
    )
  );
};

export default ModalPGDasIntegraContador;
