import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { Reducers } from '../../../store/ducks';
import { toggleMobileSidebar, toggleSidebar } from '../../../store/ducks/global.duck';
import SidebarLightDarkMode from './SidebarLightDarkMode';

import calimaFree from '../../../assets/calimaFree.png';
import calimaFreeMini from '../../../assets/calimaFreeMini.png';
import calimaLite from '../../../assets/calimaLite.png';
import calimaLiteMini from '../../../assets/calimaLiteMini.png';
import calimaPro from '../../../assets/calimaPro.png';
import calimaProMini from '../../../assets/calimaProMini.png';

export default function SidebarHeader() {
  const {
    globalReducer: { globalParameter, sidebarToggle },
    proReducer: { isPro },
  } = useSelector<Reducers, Reducers>(state => state);

  const dispatch = useDispatch();

  function toggle() {
    dispatch(toggleSidebar());
  }

  function toggleMobile() {
    dispatch(toggleMobileSidebar());
  }

  const getLogo = () => {
    let logo = sidebarToggle ? calimaLiteMini : calimaLite;
    if (globalParameter.acesso.gratuito) {
      logo = sidebarToggle ? calimaFreeMini : calimaFree;
    } else if (isPro) {
      logo = sidebarToggle ? calimaProMini : calimaPro;
    }
    return logo;
  };

  return (
    <div className="app-sidebar--header">
      <SidebarLightDarkMode />
      <Link to="#" title="Calima ERP Contábil" className="app-sidebar-logo">
        <img src={getLogo()} className={cx('logo-calima', { mini: sidebarToggle })} />
      </Link>
      <button className="btn btn-sm collapse-sidebar-btn" id="CollapseSidebarTooltip" onClick={toggle}>
        <FontAwesomeIcon icon={['fas', 'outdent']} size="lg" />
      </button>
      <UncontrolledTooltip target="CollapseSidebarTooltip" container=".app-sidebar" placement="right">
        Ocultar menu
      </UncontrolledTooltip>
      <button className="navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn" onClick={toggleMobile}>
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <button className="expand-sidebar-btn btn btn-sm" id="ExpandSidebarTooltip" onClick={toggle}>
        <FontAwesomeIcon icon={['fas', 'indent']} size="lg" />
      </button>
      <UncontrolledTooltip target="ExpandSidebarTooltip" container=".app-sidebar" placement="right">
        Exibir menu
      </UncontrolledTooltip>
    </div>
  );
}
