import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import {
  alertManager,
  AlertType,
  AutoCompleteOff,
  Box,
  Checkbox,
  currentDate,
  dispatchClose,
  EditForm,
  EditViewProps,
  ERROR,
  format,
  InputGroupInline,
  InputType,
  isNotEmpty,
  ProcessBar,
  Radio,
  RadioItemProps,
  showToast,
  SUCCESS,
  WindowComponent,
} from 'summer';
import { CLASS_NAME, ConfiguracaoMIC } from '../../../../models/dtos/mic/configuracaoMIC';
import { MIC_TIPO_AMBIENTE, OFICIAL } from '../../../../models/enumerated/mic/tipoAmbienteEnum';
import { atualizarGlobalParameter } from '../../../../services/ger.service';
import { salvarConfiguracaoGeral } from '../../../../services/mic.service';
import { Reducers } from '../../../../store/ducks';
import { GlobalState, setGlobalParameter } from '../../../../store/ducks/global.duck';
import { gerarDias } from '../../../../utilities/esocial.util';
import { tooltipCredenciaisSERPRO } from '../../../../utilities/mic.util';
import AjudaButton from '../../../components/mic/ajudaButton/AjudaButton';
import CertificadoForm, { validarSenhaCertificado } from '../../ger/certificadoDigital/CertificadoForm';
import { verificarItensEmBranco } from '../../mfp/processoTrabalhista/processoTrabalhista.util';

interface ScreenFields {
  emitirDCTFWeb?: boolean;
  emitirDCTFWeb13?: boolean;
  emitirPGDAS?: boolean;
  emitirPGMEI?: boolean;
  buscarCaixaPostal?: boolean;
  consultarPagamentos?: boolean;
  emitirSITFIS?: boolean;
  emitirCND?: boolean;
}

export const defaultValues: ConfiguracaoMIC = {
  diaBuscaCaixaPostal: 1,
  diaConsultaPagamentos: 31,
  diaEmissaoCND: 1,
  diaEmissaoDctfweb: 11,
  diaEmissaoDctfweb13: 16,
  diaEmissaoPgdas: 15,
  diaEmissaoPgmei: 15,
  diaEmissaoSitfis: 1,
  emitirCNDVencimento: 'true',
};

const ConfiguracaoView: FC<EditViewProps<ConfiguracaoMIC> & RouteComponentProps> = props => {
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const formProps = useForm<ConfiguracaoMIC & ScreenFields>({
    defaultValues: {
      tipoAmbiente: OFICIAL.value,
    },
    mode: 'onBlur',
  });
  const [certificadoAtual, setCertificadoAtual] = useState();
  const dispatch = useDispatch();

  const radioItems: RadioItemProps[] = [
    { label: 'Consultar todo mês', value: 'false', disabled: !formProps.getValues().emitirCND },
    { label: 'Consultar no vencimento', value: 'true', disabled: !formProps.getValues().emitirCND },
  ];

  useEffect(
    () => {
      const { configuracaoMIC } = globalParameter;
      const emitirCNDVencimento = configuracaoMIC.emitirCNDVencimento === undefined ? 'true' : configuracaoMIC.emitirCNDVencimento.toString();
      formProps.reset({
        ...configuracaoMIC,
        buscarCaixaPostal: isNotEmpty(configuracaoMIC.diaBuscaCaixaPostal),
        consultarPagamentos: isNotEmpty(configuracaoMIC.diaConsultaPagamentos),
        emitirCND: isNotEmpty(configuracaoMIC.diaEmissaoCND) || emitirCNDVencimento === 'true',
        emitirCNDVencimento,
        emitirDCTFWeb: isNotEmpty(configuracaoMIC.diaEmissaoDctfweb),
        emitirDCTFWeb13: isNotEmpty(configuracaoMIC.diaEmissaoDctfweb13),
        emitirPGDAS: isNotEmpty(configuracaoMIC.diaEmissaoPgdas),
        emitirPGMEI: isNotEmpty(configuracaoMIC.diaEmissaoPgmei),
        emitirSITFIS: isNotEmpty(configuracaoMIC.diaEmissaoSitfis),
      });
      formProps.control.reRender();
      setCertificadoAtual(configuracaoMIC.arquivoCertificadoDigital);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const validateExtras = () => {
    const erros = [];

    const {
      arquivos,
      arquivoCertificadoDigital,
      senhaCertificadoDigital,
      consumerKey,
      consumerSecret,
      consumerKeyCND,
      consumerSecretCND,
    } = formProps.getValues();
    const acessoContador = [
      { label: 'Certificado digital', value: arquivoCertificadoDigital || arquivos },
      { label: 'Senha certificado digital', value: senhaCertificadoDigital },
      { label: 'Consumer Key Geral', value: consumerKey },
      { label: 'Consumer Secret Geral', value: consumerSecret },
    ];
    verificarItensEmBranco(erros, acessoContador, true);

    validarSenhaCertificado(senhaCertificadoDigital, erros);

    const acessoCND = [
      { label: 'Consumer Key CND', value: consumerKeyCND },
      { label: 'Consumer Secret CND', value: consumerSecretCND },
    ];
    verificarItensEmBranco(erros, acessoCND, true);

    return erros;
  };

  const handleSubmit = (formData: ConfiguracaoMIC) =>
    new Promise<void>(resolve => {
      const listaAutomacoes = [
        { label: 'DCTFWeb', field: 'diaEmissaoDctfweb' },
        { label: 'DCTFWeb 13º salário', field: 'diaEmissaoDctfweb13' },
        { label: 'PGDAS', field: 'diaEmissaoPgdas' },
        { label: 'PGMEI', field: 'diaEmissaoPgmei' },
        { label: 'SITFIS', field: 'diaEmissaoSitfis' },
        { label: 'CND', field: 'diaEmissaoCND' },
        { label: 'Caixa Postal', field: 'diaBuscaCaixaPostal' },
        { label: 'Pagamento', field: 'diaConsultaPagamentos' },
      ];
      const diaAtual = Number(format(currentDate(), 'DD'));
      const listaAutomacoesDiaAtual = listaAutomacoes
        .filter(
          it => formData[it.field] === diaAtual && globalParameter.configuracaoMIC && formData[it.field] !== globalParameter.configuracaoMIC[it.field]
        )
        .map(it => it.label);
      if (listaAutomacoesDiaAtual.length > 0) {
        const s = listaAutomacoesDiaAtual.length === 1 ? '' : 's';
        const serao = listaAutomacoesDiaAtual.length === 1 ? 'será' : 'serão';
        alertManager.emit({
          message: (
            <>
              <div className="text-center mb-2">
                Você alterou o{s} seguinte{s} robô{s} para ativação no dia {diaAtual}:
              </div>
              {listaAutomacoesDiaAtual.map(it => (
                <div key={it} className="text-center">
                  • {it}
                </div>
              ))}
              <div className="text-center my-2">
                Isso significa que {serao} <strong>executado{s} hoje</strong>.
              </div>
              <div className="text-center">Deseja continuar?</div>
            </>
          ),
          onCancelClick: resolve,
          onNoClick: () => finishSubmit(formData, resolve, false),
          onOkClick: () => finishSubmit(formData, resolve, true),
          textWarningNo: 'Não, executar a partir do mês que vem',
          textWarningYes: 'Sim, executar hoje',
          type: AlertType.WARNING_YES_NO_CANCEL,
        });
      } else {
        finishSubmit(formData, resolve, false);
      }
    });

  const finishSubmit = (formData: ConfiguracaoMIC, resolve: any, processarAutomacao: boolean) => {
    salvarConfiguracaoGeral(formData, processarAutomacao, {
      errorFunction: mensagem => {
        resolve();
        showToast(mensagem, ERROR);
      },
      thenFunction: () => {
        atualizarGlobalParameter(globalParameter.usuario, {
          errorFunction: mensagem => showToast(mensagem, ERROR),
          thenFunction: result => {
            dispatch(setGlobalParameter(result));
            resolve();
            showToast('Configuração atualizada com sucesso.', SUCCESS);
            handleClose();
          },
        });
      },
    });
  };

  const handleClose = () => {
    dispatchClose(props, props.history, true);
  };

  const customRemove = () => {
    formProps.setValue([{ arquivoCertificadoDigital: null }, { nomeCertificadoDigital: null }, { senhaCertificadoDigital: null }]);
  };

  const changeGeracaoAutomatica = (idCheck: string, idCampo: string, valorPadrao: number) => () => {
    if (formProps.getValues()[idCheck]) {
      formProps.setValue(idCampo, valorPadrao);
    } else {
      formProps.setValue(idCampo, null);
    }
  };

  const changeCNDAutomatica = () => {
    if (formProps.getValues().emitirCND) {
      formProps.setValue([{ emitirCNDVencimento: 'true' }, { diaEmissaoCND: defaultValues.diaEmissaoCND }]);
    } else {
      formProps.setValue([{ emitirCNDVencimento: 'false' }, { diaEmissaoCND: null }]);
    }
  };

  const cardConfiguracaoDia = (idCampo: string, idCheck: string, labelCheck: string, tooltipMsg: string, ultimoDia: number = 31) => (
    <Col xs={12} md={4} className="pb-2">
      <Card className="p-2" style={{ borderWidth: 'thin' }}>
        <FormGroup tag={Row}>
          <Col>
            <Checkbox
              formProps={formProps}
              id={idCheck}
              label={labelCheck}
              onChange={changeGeracaoAutomatica(idCheck, idCampo, defaultValues[idCampo])}
              tooltipProps={{ message: tooltipMsg }}
            />
          </Col>
        </FormGroup>
        <Row>
          <Col>
            <InputGroupInline
              id={idCampo}
              label="No dia:"
              formProps={{ ...formProps, validation: { required: formProps.getValues()[idCheck] } }}
              inputDropDownProps={{ optionsProvider: gerarDias(ultimoDia) }}
              type={InputType.DROP_DOWN}
              disabled={!formProps.getValues()[idCheck]}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );

  const changeConsultaCND = value => {
    formProps.setValue('diaEmissaoCND', value === 'true' ? null : defaultValues.diaEmissaoCND);
  };

  return (
    <WindowComponent {...props} title={['Manutenção', 'Configuração']}>
      <EditForm formProps={formProps} editViewProps={props} dtoClassName={CLASS_NAME} externalSubmit={true} disableInitialAutoFocus={true}>
        <Row className="mb-2">
          <Col xs={12} md={4}>
            <InputGroupInline
              id="tipoAmbiente"
              label="Tipo de ambiente"
              formProps={{ ...formProps, validation: { required: true } }}
              inputDropDownProps={{ optionsProvider: MIC_TIPO_AMBIENTE }}
              type={InputType.DROP_DOWN}
              tooltipProps={{
                message:
                  'O ambiente de teste utiliza dados meramente ilustrativos e não gera cobranças na Área do Cliente SERPRO. Por outro lado, o ambiente oficial utiliza dados reais de suas empresas e gera cobranças na Área do Cliente SERPRO.',
              }}
            />
          </Col>
        </Row>
        <Box title="Automatização dos robôs" className="mt-0 mb-3">
          <Row>
            {cardConfiguracaoDia(
              'diaEmissaoDctfweb',
              'emitirDCTFWeb',
              'Gerar automaticamente a DCTFWeb',
              'Marque aqui para transmitir e gerar automaticamente o DARF da DCTFWeb'
            )}
            {cardConfiguracaoDia(
              'diaEmissaoDctfweb13',
              'emitirDCTFWeb13',
              'Gerar automaticamente a DCTFWeb 13º salário',
              'Marque aqui para transmitir e gerar automaticamente o DARF de 13º salário da DCTFWeb',
              20
            )}
            {cardConfiguracaoDia(
              'diaEmissaoPgdas',
              'emitirPGDAS',
              'Gerar automaticamente o PGDAS',
              'Marque aqui para entregar automaticamente a declaração mensal ao PGDAS'
            )}
          </Row>
          <Row>
            {cardConfiguracaoDia(
              'diaEmissaoPgmei',
              'emitirPGMEI',
              'Gerar automaticamente o PGMEI',
              'Marque aqui para gerar automaticamente o DAS do PGMEI'
            )}
            {cardConfiguracaoDia(
              'diaEmissaoSitfis',
              'emitirSITFIS',
              'Gerar automaticamente o SITFIS',
              'Marque aqui para gerar automaticamente o relátorio de situação fiscal'
            )}
            <Col xs={12} md={4} className="pb-2">
              <Card className="p-2" style={{ borderWidth: 'thin' }}>
                <FormGroup tag={Row}>
                  <Col>
                    <Checkbox
                      formProps={formProps}
                      id="emitirCND"
                      label="Consultar automaticamente a CND"
                      onChange={changeCNDAutomatica}
                      tooltipProps={{ message: 'Marque aqui para consultar automaticamente a certidão negativa de débitos' }}
                    />
                  </Col>
                </FormGroup>
                <Radio inline={true} formProps={formProps} id="emitirCNDVencimento" items={radioItems} onChange={changeConsultaCND} />
                <Row className={cx({ 'd-none': formProps.getValues().emitirCNDVencimento === 'true' })}>
                  <Col>
                    <InputGroupInline
                      id="diaEmissaoCND"
                      label="No dia:"
                      formProps={{
                        ...formProps,
                        validation: { required: formProps.getValues().emitirCND && formProps.getValues().emitirCNDVencimento === 'false' },
                      }}
                      inputDropDownProps={{ optionsProvider: gerarDias(31) }}
                      type={InputType.DROP_DOWN}
                      disabled={!formProps.getValues().emitirCND}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            {cardConfiguracaoDia(
              'diaBuscaCaixaPostal',
              'buscarCaixaPostal',
              'Buscar automaticamente a Caixa Postal',
              'Marque aqui para atualizar automaticamente as mensagens da Caixa Postal'
            )}
            {cardConfiguracaoDia(
              'diaConsultaPagamentos',
              'consultarPagamentos',
              'Consultar pagamentos das guias',
              'Marque aqui para consultar automaticamente o pagamento das guias'
            )}
          </Row>
        </Box>
        <Box title="Suas credenciais da Área do Cliente SERPRO" className="mt-0 mb-3">
          <AutoCompleteOff>
            <CertificadoForm
              certificadoAtual={certificadoAtual}
              setCertificadoAtual={setCertificadoAtual}
              formProps={formProps}
              required={false}
              extraContent={
                <>
                  <Col>
                    <InputGroupInline
                      id="consumerKey"
                      label="Consumer Key"
                      formProps={{ ...formProps, validation: { maxLength: 120 } }}
                      {...tooltipCredenciaisSERPRO()}
                    />
                  </Col>
                  <Col>
                    <InputGroupInline
                      id="consumerSecret"
                      label="Consumer Secret"
                      formProps={{ ...formProps, validation: { maxLength: 120 } }}
                      {...tooltipCredenciaisSERPRO()}
                    />
                  </Col>
                </>
              }
              customRemove={customRemove}
              customTopContent={
                <>
                  <h6 className="font-weight-bold mb-1">Geral (Integra Contador)*</h6>
                  <span className="font-size-sm opacity-5">
                    Aqui você irá fazer upload do seu certificado digital, bem como o preenchimento obrigatório dos campos de senha, consumer key e
                    consumer secret. Isto é necessário para o funcionamento do DCTFWeb, PGDAS, PGMEI, SITFIS e Caixa Postal.
                  </span>
                  <div className="divider mt-1 mb-3" />
                </>
              }
            />
            <h6 className="font-weight-bold mt-3 mb-1">CND</h6>
            <span className="font-size-sm opacity-5">
              Os campos abaixo são opcionais e só devem ser preenchidos pelos clientes que desejam utilizar o serviço CND.
            </span>
            <div className="divider mt-1 mb-3" />
            <Row>
              <Col xs={12} lg={4}>
                <InputGroupInline
                  id="consumerKeyCND"
                  label="Consumer Key"
                  formProps={{ ...formProps, validation: { maxLength: 120 } }}
                  {...tooltipCredenciaisSERPRO(' CND')}
                />
              </Col>
              <Col xs={12} lg={4}>
                <InputGroupInline
                  id="consumerSecretCND"
                  label="Consumer Secret"
                  formProps={{ ...formProps, validation: { maxLength: 120 } }}
                  {...tooltipCredenciaisSERPRO(' CND')}
                />
              </Col>
            </Row>
          </AutoCompleteOff>
          <div className="d-flex justify-content-end">
            <AjudaButton wikiId="/mic/configuracaoView" />
          </div>
        </Box>
        <Row>
          <Col>
            <ProcessBar
              validateExtras={validateExtras}
              handleSubmit={formProps.handleSubmit(handleSubmit)}
              handleClose={handleClose}
              calimaPro={true}
            />
          </Col>
        </Row>
      </EditForm>
    </WindowComponent>
  );
};

export { CLASS_NAME };
export default ConfiguracaoView;
