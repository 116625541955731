import { MenuItem } from '../../../models/dtos/ger/menuItem';

const mhoMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/mho/dashboardnitrus',
  },
  {
    content: [
      {
        label: 'Integração manual do Calima com o Nitrus',
        to: '/mho/integracaoNitrusView',
      },
      {
        label: 'Acessar o Nitrus agora',
        to: 'https://www.nitrus.app/login',
      },
      {
        label: 'Conheça o Nitrus',
        to: 'https://www.nitrus.com.br/',
      },
      {
        isVisible: parameter => parameter.acessarHonorariosIfHasMovimentacao,
        label: 'Acessar Módulo Honorários (antigo)',
        to: '/mho/dashboard',
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Customização de termos',
        to: '/mho/customizacaoTermoMHOSearchView',
      },
      {
        label: 'Contrato de prestação de serviços',
        to: '/mho/contratoPrestacaoServicoReportView',
      },
      {
        label: 'Termo de consentimento para tratamento de dados pessoais - LGPD',
        to: '/mho/consentimentoLGPDReportView',
      },
    ],
    icon: 'pe-7s-id',
    label: 'Gestão de contratos',
  },
];

export default mhoMenu;
