import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Popover, UncontrolledTooltip } from 'reactstrap';
import { generateTestId } from 'summer';
import logoBox from '../../../assets/calima_box_icon.svg';
import logoConnect from '../../../assets/calima_connect_icon.svg';
import logoMic from '../../../assets/calima_mic_icon.svg';
import logoNitrus from '../../../assets/calima_nitrus_icon.svg';
import logoSite from '../../../assets/calima_site_icon.svg';
import { possuiSuporteTicket } from '../../../dependencies/acessoDependency';
import { useCrispAction } from '../../../hooks/useCrispAction';
import useLoading from '../../../hooks/useLoading';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import { abrirMinhaConta, abrirSiteBackup, abrirSuporteTicket, openMD5 } from '../../../utilities/menu.util';
import {
  URL_AJUDA_CALIMA,
  URL_BOX,
  URL_NITRUS,
  URL_PROACADEMY,
  URL_PROJETUS_REMOTO_TV,
  URL_PROJETUS_VIDEOS,
  URL_TREINAMENTO,
  URL_VENDA_CONNECT,
  URL_VENDA_MIC,
  URL_VENDA_SITE,
} from '../../../utilities/wsUtil';
import InformacaoRegistroProcessView from '../../pages/ger/informacaoRegistro/InformacaoRegistroProcessView';
import SatisfacaoView from '../../pages/ger/satisfacao/SatisfacaoView';
import ButtonHeaderApp from '../buttonHeaderApp/ButtonHeaderApp';

const HeaderAppsMenu: FC = () => {
  const { globalParameter, currentState } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const [visible, setVisible] = useState(false);
  const [visibleOtherProducts, setVisibleOtherProducts] = useState(true);
  const [modal, setModal] = useState(null);
  const crispAction = useCrispAction();
  const loading = useLoading();

  const toggle = () => {
    setVisible(!visible);
  };

  const openMyAccount = () => {
    abrirMinhaConta(globalParameter);
  };

  const openChatCrisp = () => {
    crispAction.openComercial();
  };

  const onCloseModal = () => setModal(null);

  const abrirAjudaCalima = () => window.open(URL_AJUDA_CALIMA, '_blank');

  const abrirPesquisaSatisfacao = () => {
    toggle();
    setModal(<SatisfacaoView onClose={onCloseModal} />);
  };

  const abrirInfoRegistro = () => {
    toggle();
    setModal(<InformacaoRegistroProcessView onClose={onCloseModal} />);
  };

  const callAbrirSuporteTicket = () => abrirSuporteTicket(currentState, globalParameter, loading, crispAction);

  const abrirProAcademy = () => openMD5(URL_PROACADEMY, globalParameter);

  const abrirBackup = () => abrirSiteBackup(globalParameter);

  const hideOthersProducts = () => setVisibleOtherProducts(!visibleOtherProducts);

  const desabilitarSuporte = !globalParameter.usuario.acessoSuporte;

  return (
    <>
      {modal}
      <Button
        id="menuAppsPopover"
        className={cx('btn-pill d-40 p-0', { 'menu-apps-active': visible })}
        {...generateTestId('menuAppsPopover')}
        onClick={toggle}
      >
        <FontAwesomeIcon icon="grip-vertical" size="lg" />
        <UncontrolledTooltip placement="left" target="menuAppsPopover">
          Aplicativos
        </UncontrolledTooltip>
      </Button>
      <Popover
        target="menuAppsPopover"
        trigger="legacy"
        className="popover-custom-wrapper popover-custom-lg popover-apps menu-apps-popover"
        placement="auto"
        isOpen={visible}
        toggle={toggle}
      >
        <Card style={{ background: '#253053', borderRadius: '17px' }}>
          <Card style={{ background: '#F1F2FB', borderRadius: '17px' }}>
            <div className="px-3 d-flex">
              <Button
                className="btn rounded-pill nav-link active my-3"
                onClick={openMyAccount}
                style={{ backgroundColor: '#3C44B1', borderColor: '#3C44B1', color: '#fff', height: '40px', width: '-webkit-fill-available' }}
              >
                <FontAwesomeIcon icon="user" size={'lg'} className="mr-2" />
                Minha conta
              </Button>
              <Button onClick={abrirAjudaCalima} title="Ajuda" toggle={toggle} style={{ background: 'transparent', border: '0', color: '#253053' }}>
                <FontAwesomeIcon icon="question-circle" size={'2x'} />
              </Button>
            </div>
            <div className="px-3 d-flex text-center align-items-center mb-1">
              <div className="divider bg-dark col-3" />
              <span className="col-6 text-divider">Saiba mais</span>
              <div className="divider bg-dark col-3" />
            </div>
            <div className="px-3 text-center">
              <ButtonHeaderApp url={URL_PROJETUS_VIDEOS} icon={faYoutube} title="Nosso Youtube" toggle={toggle} />
              <ButtonHeaderApp url={URL_TREINAMENTO} icon="book-reader" title="Solicitar Treinamento" toggle={toggle} className="mx-4" />
              <ButtonHeaderApp onClick={abrirProAcademy} icon="graduation-cap" title="ProAcademy" toggle={toggle} />
            </div>
            <div className="mb-1 px-3 d-flex text-center align-items-center">
              <div className="divider bg-dark col-3" />
              <span className="col-6 text-divider">Atendimento e suporte</span>
              <div className="divider bg-dark col-3" />
            </div>
            <div className="px-3 text-center">
              <ButtonHeaderApp onClick={abrirPesquisaSatisfacao} icon="smile" title="Satisfação" toggle={toggle} disabled={desabilitarSuporte} />
              <ButtonHeaderApp
                onClick={callAbrirSuporteTicket}
                dependency={[() => possuiSuporteTicket(globalParameter, crispAction.openComercial)]}
                icon="scroll"
                title="Suporte via Ticket"
                toggle={toggle}
                className="mx-4"
                disabled={desabilitarSuporte}
              />
              <ButtonHeaderApp
                onClick={abrirInfoRegistro}
                icon="info-circle"
                title="Inf. de Registro"
                toggle={toggle}
                disabled={desabilitarSuporte}
              />
            </div>
            <div className="px-3 text-center">
              <ButtonHeaderApp onClick={abrirBackup} icon="save" title="Backup" toggle={toggle} disabled={desabilitarSuporte} />
              <ButtonHeaderApp
                url={URL_PROJETUS_REMOTO_TV}
                icon="rss"
                title="Projetus Remoto"
                toggle={toggle}
                className="mx-4"
                disabled={desabilitarSuporte}
              />
              <ButtonHeaderApp onClick={openChatCrisp} icon="shopping-cart" title="Comprar Serviços" toggle={toggle} disabled={desabilitarSuporte} />
            </div>
            <div className="d-flex text-center align-items-center align-self-center mb-2">
              <Button onClick={hideOthersProducts} style={{ background: 'transparent', border: '0', color: '#253053' }}>
                <FontAwesomeIcon icon={visibleOtherProducts ? 'caret-down' : 'caret-up'} size={'2x'} />
              </Button>
            </div>
          </Card>
          <div className={cx({ 'd-none': visibleOtherProducts })}>
            <div className="px-3 d-flex justify-content-center mt-3">
              <span className="text-divider text-white">Outros produtos ProjetusTI</span>
            </div>
            <div className="px-3 text-center outros_produtos">
              <ButtonHeaderApp url={URL_NITRUS} logo={logoNitrus} title="Nitrus" toggle={toggle} className="button-nitrus" />
              <ButtonHeaderApp url={URL_VENDA_CONNECT} logo={logoConnect} title="Connect" toggle={toggle} className="button-connect mx-4" />
              <ButtonHeaderApp url={URL_BOX} logo={logoBox} title="Box" toggle={toggle} className="button-box" />
            </div>
            <div className="px-3 text-center outros_produtos">
              <ButtonHeaderApp url={URL_VENDA_SITE} logo={logoSite} title="Site" toggle={toggle} className="button-site mr-2" />
              <ButtonHeaderApp url={URL_VENDA_MIC} logo={logoMic} title="Integra Contador" toggle={toggle} className="button-mic ml-2" />
            </div>
          </div>
        </Card>
      </Popover>
    </>
  );
};

export default HeaderAppsMenu;
