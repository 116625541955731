import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Reducers } from '../../../store/ducks';
import { GlobalState, setCurrentState } from '../../../store/ducks/global.duck';

import './ListItem.css';

export interface ListItemProps {
  buttonId: string;
  modulo: string;
  titulo: string;
  itens?: any[];
  toggle?: () => void;
}

const ListItem: FC<ListItemProps> = props => {
  const { currentState } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const onClick = e => {
    e.preventDefault();
    if (currentState !== props.modulo) {
      dispatch(setCurrentState(props.modulo));
    }
    history.push(e.currentTarget.getAttribute('href'));
    props.toggle();
    document.getElementById(props.buttonId).blur();
  };

  return (
    <Nav
      pills={true}
      className={cx('list-item flex-column p-3 d-inline-block align-top', `nav-neutral-${props.modulo.toLowerCase()}`)}
      style={{ width: 310 }}
    >
      <NavItem className={cx('nav-item pb-2 text-uppercase font-weight-bold font-size-sm', `text-${props.modulo.toLowerCase()}`)}>
        <span>{props.titulo}</span>
      </NavItem>
      <PerfectScrollbar>
        {props.itens.map((it, index) => (
          <NavItem key={index} className={`header-search-item-${props.modulo.toLowerCase()}`}>
            <NavLink href={it.to} onClick={onClick} className="pl-2">
              <div className="mr-2 d-flex">
                <FontAwesomeIcon icon={['fas', 'circle']} className="opacity-8" style={{ fontSize: '8px' }} />
              </div>
              <span>{it.label}</span>
            </NavLink>
          </NavItem>
        ))}
      </PerfectScrollbar>
    </Nav>
  );
};

export default ListItem;
