import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { Button, Popover, UncontrolledTooltip } from 'reactstrap';
import { getMenuItem, ordenarModulos } from '../../../utilities/menu.util';
import { navegateOptionsMenu, setFirstItemSelected } from '../../../utilities/navegacaoTelasSetas.util';
import ListItem from './ListItem';

import './List.css';

interface HeaderMultiListProps {
  id: string;
  title: string;
  list: any[];
  icon?: IconProp;
  mobile?: boolean;
  buttonColor?: string;
  className?: string;
}

const divClass = 'popover-header-multi-list';

const HeaderMultiList: FC<HeaderMultiListProps> = props => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setFirstItemSelected(divClass, true);
        document.querySelector('body').style.overflow = 'hidden';
        document.addEventListener('keydown', navegateOptionsMenu);
      }, 500);
    }
  }, [isOpen]);

  const getList = () => {
    const map: any = {};

    props.list.forEach(it => {
      if (Object.keys(map).indexOf(it.modulo) === -1) {
        map[it.modulo] = [];
      }
      map[it.modulo].push(it);
    });

    return ordenarModulos(Object.keys(map).map(it => getMenuItem({ modulo: it, itens: map[it] })));
  };

  const getButton = () => {
    let result = (
      <Button className={cx('listDesktop', props.className, { 'active-multilist': isOpen })} color="link" size="sm" id={props.id}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon className="mr-2" icon={props.icon} />
          <span>{props.title}</span>
          <FontAwesomeIcon className="ml-2" icon={['fas', isOpen ? 'angle-up' : 'angle-down']} />
        </div>
      </Button>
    );

    if (props.mobile) {
      result = (
        <Button outline={true} id={props.id} color={props.buttonColor} className={cx('btn-pill d-40 p-0 listMobile', props.className)}>
          <FontAwesomeIcon icon={props.icon} />
          <UncontrolledTooltip placement="left" target={props.id}>
            Visualizar {props.title}
          </UncontrolledTooltip>
        </Button>
      );
    }

    return result;
  };

  const toggle = () => {
    if (isOpen) {
      document.querySelector('body').style.overflow = 'auto';
      document.removeEventListener('keydown', navegateOptionsMenu);
    }
    setIsOpen(p => !p);
  };

  return props.list.length ? (
    <>
      {getButton()}
      <Popover
        target={props.id}
        className={`popover-list popover-custom-wrapper ${divClass}`}
        placement="auto"
        container="body"
        trigger="legacy"
        isOpen={isOpen}
        toggle={toggle}
      >
        {getList().map((it, index) => (
          <ListItem key={index} buttonId={props.id} modulo={it.modulo} titulo={it.titulo} itens={it.itens} toggle={toggle} />
        ))}
      </Popover>
    </>
  ) : null;
};

export default HeaderMultiList;
