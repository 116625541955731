import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, eventManager, EventType, showToast } from 'summer';
import { CLIQUE, FAV } from '../../../models/enumerated/ger/usuarioHistAcessoTipoEnum';
import { atualizarAcessoFavorito, buscarHistoricoAcesso } from '../../../services/ger.service';
import { histAcessoActions, Reducers } from '../../../store/ducks';

const FavRecentControl = () => {
  const {
    histAcessoReducer: { favs },
    globalReducer: { globalParameter },
  } = useSelector<Reducers, Reducers>(state => state);

  const dispatch = useDispatch();

  useEffect(
    () => {
      buscarHistoricoAcesso(globalParameter.usuario.id, {
        errorFunction: message => showToast(message, ERROR),
        thenFunction: res => {
          dispatch(histAcessoActions.setFavs(res.filter(it => it.tipo === FAV.value)));
          dispatch(histAcessoActions.setRecents(res.filter(it => it.tipo === CLIQUE.value)));
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      eventManager.on(EventType.FAV_CHECK, onFavCheck);
      eventManager.on(EventType.FAV_TOGGLE, onFavToggle);
      return () => {
        eventManager.off(EventType.FAV_CHECK);
        eventManager.off(EventType.FAV_TOGGLE);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [favs]
  );

  const onFavCheck = data => {
    eventManager.emit(
      EventType.FAV_RESULT,
      data.modulo,
      data.id,
      favs.find(it => it.modulo === data.modulo && it.itemMenu === data.id) !== undefined
    );
  };

  const onFavToggle = data => {
    atualizarAcessoFavorito(globalParameter.usuario.id, data.modulo, data.id, {
      errorFunction: message => showToast(message, ERROR),
      thenFunction: res => {
        if (res) {
          dispatch(histAcessoActions.setFavs([...favs, { modulo: data.modulo, itemMenu: data.id }]));
        } else {
          dispatch(histAcessoActions.setFavs(favs.filter(it => it.modulo !== data.modulo || it.itemMenu !== data.id)));
        }
        eventManager.emit(EventType.FAV_RESULT, data.modulo, data.id, res);
      },
    });
  };

  return <></>;
};

export default FavRecentControl;
