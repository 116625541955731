import cx from 'clsx';
import React, { FC, ReactNode } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FileInputPreview, InputGroupInline, InputType, UseFormProps } from 'summer';
import AlertCertificado from './AlertCertificado';

export const LIMIT_SIZE_BYTES = 204800;
const REGEX_ONLY_ASCII = /^[\x00-\x7F]*$/;

export const validarSenhaCertificado = (senhaCertificadoDigital, erros) => {
  if (senhaCertificadoDigital && !REGEX_ONLY_ASCII.test(senhaCertificadoDigital)) {
    erros.push('A senha informada possui caracteres não suportados pelo Calima.');
  }
};

interface CertificadoFormProps {
  certificadoAtual: any;
  setCertificadoAtual: (certificadoAtual: any) => void;
  formProps: UseFormProps;
  required?: boolean;
  extraContent?: ReactNode;
  customRemove?: () => void;
  customTopContent?: ReactNode;
}

const CertificadoForm: FC<CertificadoFormProps> = ({
  certificadoAtual,
  setCertificadoAtual,
  formProps,
  required = true,
  extraContent,
  customRemove,
  customTopContent,
}) => {
  const removerCertificado = () => {
    setCertificadoAtual(undefined);
    if (customRemove) {
      customRemove();
    }
  };

  return (
    <>
      <AlertCertificado />
      {customTopContent}
      <Row className={cx({ 'd-none': !certificadoAtual })}>
        <InputGroupInline id="arquivoCertificadoDigital" formProps={formProps} hidden={true} />
        <Col>
          <InputGroupInline id="nomeCertificadoDigital" formProps={formProps} label="Certificado digital" disabled={true} />
        </Col>
        <Col className="col-auto" style={{ marginTop: 20 }}>
          <Button size="sm" className="btn-danger" onClick={removerCertificado}>
            Remover certificado
          </Button>
        </Col>
      </Row>
      <Row className={cx({ 'd-none': certificadoAtual })}>
        <Col>
          <FileInputPreview
            acceptedTypes=".pfx"
            formProps={formProps}
            isMultiple={false}
            limitSizeBytes={LIMIT_SIZE_BYTES}
            propImageName="arquivos"
          />
        </Col>
      </Row>
      <Row className={cx({ 'mt-2': !certificadoAtual })}>
        <Col>
          <InputGroupInline
            formProps={{ ...formProps, validation: { required, maxLength: 150 } }}
            id="senhaCertificadoDigital"
            label="Senha certificado digital"
            type={InputType.PASSWORD}
          />
        </Col>
        {extraContent}
      </Row>
    </>
  );
};

export default CertificadoForm;
