import { Key } from 'summer';

const classSelected = 'selected';
const heightScroll = -1000;
let divClass = '';
let currentLI = 0;
let enableArrowsLeftRight = false;

const getLIItems = () => document.querySelectorAll(`.${divClass} li a`);

export const setFirstItemSelected = (clazz, enable = false) => {
  divClass = clazz;
  enableArrowsLeftRight = enable;
  const listItems = getLIItems();
  if (listItems && listItems.length > 0) {
    if (listItems[currentLI]) {
      listItems[currentLI].classList.remove(classSelected);
    }
    currentLI = 0;
    listItems[currentLI].classList.add(classSelected);
    followScroll(heightScroll);
  }
};

export const followScroll = (height?: number) => {
  const itemSelected: any = document.querySelector(`.${divClass} li a.${classSelected}`);
  const classFmt = divClass.replace('popover-', '');

  let scrollDiv = document.querySelector(`.popover-${classFmt} > .popover`);
  if (itemSelected.parentNode && itemSelected.parentNode.parentNode && itemSelected.parentNode.parentNode.classList.contains('scrollbar-container')) {
    scrollDiv = itemSelected.parentNode.parentNode;
  }
  scrollDiv.scrollTop = height || itemSelected.offsetTop - 150;
};

export const navegateOptionsMenu = event => {
  const listItems: any = getLIItems();
  if (listItems && listItems.length > 0) {
    switch (event.keyCode) {
      case Key.ENTER:
        listItems[currentLI].click();
        break;
      case Key.ARROW_DOWN:
      case enableArrowsLeftRight && Key.ARROW_RIGHT:
        listItems[currentLI].classList.remove(classSelected);
        currentLI = currentLI < listItems.length - 1 ? currentLI + 1 : 0;
        listItems[currentLI].classList.add(classSelected);
        followScroll(currentLI === 0 && heightScroll);
        break;
      case Key.ARROW_UP:
      case enableArrowsLeftRight && Key.ARROW_LEFT:
        listItems[currentLI].classList.remove(classSelected);
        currentLI = currentLI > 0 ? currentLI - 1 : listItems.length - 1;
        listItems[currentLI].classList.add(classSelected);
        followScroll();
        break;
      default:
        break;
    }
  }
};
