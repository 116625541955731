import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router';
import { Button, Nav, NavItem, NavLink, Popover, UncontrolledTooltip } from 'reactstrap';
import { getMenuItem } from '../../../utilities/menu.util';
import { navegateOptionsMenu, setFirstItemSelected } from '../../../utilities/navegacaoTelasSetas.util';

import './List.css';

interface HeaderSingleListProps {
  buttonColor?: string;
  className?: string;
  currentState?: string;
  icon?: IconProp;
  id: string;
  list: any[];
  mobile?: boolean;
  title: string;
}

const divClass = 'popover-header-single-list';

const HeaderSingleList: FC<HeaderSingleListProps> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [itens, setItens] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (props.list && props.currentState) {
      setItens(getMenuItem({ modulo: props.currentState, itens: props.list.filter(it => it.modulo === props.currentState) }).itens);
    }
  }, [props.list, props.currentState]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setFirstItemSelected(divClass);
        document.querySelector('body').style.overflow = 'hidden';
        document.addEventListener('keydown', navegateOptionsMenu);
      }, 500);
    }
  }, [isOpen]);

  const getButton = () => {
    let result = (
      <Button className={cx('listDesktop', props.className, { 'active-singlelist': isOpen })} color="link" size="sm" id={props.id}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon className="mr-2" icon={props.icon} />
          <span>{props.title}</span>
          <FontAwesomeIcon className="ml-2" icon={['fas', isOpen ? 'angle-up' : 'angle-down']} />
        </div>
      </Button>
    );

    if (props.mobile) {
      result = (
        <Button outline={true} id={props.id} color={props.buttonColor} className={cx('btn-pill d-40 p-0 listMobile', props.className)}>
          <FontAwesomeIcon icon={props.icon} />
          <UncontrolledTooltip placement="left" target={props.id}>
            Visualizar {props.title}
          </UncontrolledTooltip>
        </Button>
      );
    }

    return result;
  };

  const toggle = () => {
    if (isOpen) {
      document.querySelector('body').style.overflow = 'auto';
      document.removeEventListener('keydown', navegateOptionsMenu);
    }
    setIsOpen(p => !p);
  };

  const onClick = e => {
    e.preventDefault();
    history.push(e.currentTarget.getAttribute('href'));
    toggle();
    document.getElementById(props.id).blur();
  };

  return (
    itens.length > 0 && (
      <>
        {getButton()}
        <Popover
          target={props.id}
          className={`popover-list popover-custom-wrapper ${divClass}`}
          placement="auto"
          container="body"
          trigger="legacy"
          isOpen={isOpen}
          toggle={toggle}
        >
          <Nav pills={true} className="list-item flex-column p-3 d-inline-block align-top" style={{ width: 310 }}>
            <PerfectScrollbar>
              {itens.map((it, index) => (
                <NavItem key={index} className={`header-search-item-${props.currentState.toLowerCase()}`}>
                  <NavLink href={it.to} onClick={onClick} className="pl-2">
                    <div className="mr-2 d-flex">
                      <FontAwesomeIcon icon={['fas', 'circle']} className="opacity-8" style={{ fontSize: '8px' }} />
                    </div>
                    <span>{it.label}</span>
                  </NavLink>
                </NavItem>
              ))}
            </PerfectScrollbar>
          </Nav>
        </Popover>
      </>
    )
  );
};

export default HeaderSingleList;
