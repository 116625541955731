import { alertManager, AlertType } from 'summer';
import { GlobalParameter } from '../models/dtos/ger/globalParameter';

const ESOCIAL_HABILITADO = 'O serviço Geração do eSocial não está habilitado. Deseja adquiri-lo?';
const TIMEOUT_TO_REDIRECT = 600;

export const promisedAlert = (message, type = AlertType.WARNING_YES_NO, onOkClick = null, textWarningNo = null, textWarningYes = null) =>
  new Promise(resolve => {
    alertManager.emit({
      message,
      onOkClick: onOkClick ? onOkClick : () => resolve(),
      textWarningNo,
      textWarningYes,
      type,
    });
  });

export function abrirSpedContabil(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSpedContabil) {
    history.push('dashboard');
    promisedAlert('O serviço Geração do SPED ECD - Escrituração Contábil Digital não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}

export function gerarDIRF(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoDIRF) {
    history.push('dashboard');
    promisedAlert('O serviço Geração DIRF não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}

export function gerarRAIS(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoRAIS) {
    history.push('dashboard');
    promisedAlert('O serviço Geração RAIS não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}

export function abrirSpedECF(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSpedContabil) {
    history.push('dashboard');
    promisedAlert('O serviço Geração do SPED ECF - Escrituração Contábil Fiscal não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}

export function abrirLcdpr(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSpedContabil) {
    history.push('dashboard');
    promisedAlert('O serviço Geração do LCDPR - Livro Caixa Digital do Produtor Rural não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}

export function gerarSEFIP(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSEFIP) {
    history.push('dashboard');
    promisedAlert('O serviço Geração SEFIP não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}

export function abrirSpedFiscal(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSpedFiscal) {
    history.push('dashboard');
    promisedAlert('O serviço Geração do SPED EFD - ICMS IPI não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}

export function abrirSpedContribuicoes(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSpedFiscal) {
    history.push('dashboard');
    promisedAlert('O serviço Geração do SPED EFD - Contribuições não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}

export function abrirQualificacaoTrabalhadorEsocial(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSpedFolha) {
    history.push('dashboard');
    promisedAlert('O serviço Qualificação Cadastral não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}

export function esocialHabilitado(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSpedFolha) {
    setTimeout(() => {
      history.push('dashboard');
    }, TIMEOUT_TO_REDIRECT);
    promisedAlert(`${ESOCIAL_HABILITADO}`).then(crisp);
  }
}

export function abrirSincronizacaoEsocial(globalParameter: GlobalParameter, history, message = `${ESOCIAL_HABILITADO}`, crisp): any {
  if (!globalParameter.acesso.geracaoSpedFolha) {
    history.push('dashboard');
    promisedAlert(message).then(crisp);
  }
}

export function reinfHabilitado(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSpedFiscal) {
    history.push('dashboard');
    promisedAlert('O serviço Geração do Reinf não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}

export function abrirImportacaoQualificacaoCadastralEsocial(globalParameter: GlobalParameter, history, crisp): any {
  abrirSincronizacaoEsocial(globalParameter, history, 'O serviço Qualificação Cadastral não está habilitado. Deseja adquiri-lo?', crisp);
}

export function abrirLucroRealConfig(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSpedContabil) {
    history.push('dashboard');
    promisedAlert('O serviço Apuração de Lucro Real não está habilitado. Deseja adquiri-lo?').then(crisp);
  } else if (
    globalParameter.empresa.empresaFilial.toString() === 'true' &&
    globalParameter.empresa.matriz !== null &&
    globalParameter.empresa.matriz.centralizar === true
  ) {
    history.push('dashboard');
    promisedAlert(
      'A configuração e apuração do Lucro Real deve ocorrer na matriz. Essa exigência vem em decorrência da configuração de centralização das filiais na matriz.',
      AlertType.INFORMATION
    );
  }
}

export function possuiSuporteTicket(globalParameter: GlobalParameter, crisp): boolean {
  let result = true;
  if (!globalParameter.acesso.suporteTicket) {
    promisedAlert('O serviço SUPORTE VIA TICKET não está habilitado. Deseja adquiri-lo?').then(crisp);
    result = false;
  }
  return result;
}

export function abrirAgendamento(globalParameter: GlobalParameter, crisp): boolean {
  let result = true;
  if (globalParameter.acesso.plano === 0) {
    promisedAlert('O serviço Agendador de Processos não está habilitado. Deseja adquiri-lo?').then(crisp);
    result = false;
  }
  return result;
}

export function abrirBolsaQualificacao(globalParameter: GlobalParameter, history, crisp): any {
  if (!globalParameter.acesso.geracaoSpedContabil) {
    history.push('dashboard');
    promisedAlert('O serviço Exportação de Bolsa Qualificação não está habilitado. Deseja adquiri-lo?').then(crisp);
  }
}
